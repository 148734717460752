import BandwagonDotCom from "../../components/BandwagonDotCom"
import React from "react"
import TeamMember from "../../components/TeamMember"

import { StaticImage } from "gatsby-plugin-image"

const ChloeFowler = () => <BandwagonDotCom>
  <TeamMember
        headshot=<StaticImage src="../../images/chloe-fowler-headshot.jpg"
                alt="Chloe Fowler" />
        name="Chloe Fowler"
        position="Chief of Staff"
  >
      <p>
        Chloe Fowler is a graduate of Clemson University where she received a
        Bachelors degree in Political Science (B.A.) with a minor in Business
        Administration. As a dedicated Tiger she is currently pursuing her
        Corporate M.B.A. from Clemson’s Greenville campus. Chloe remains active
        in her community through serving on the Board for Bridge Ministry at
        Buncombe Street United Methodist Church, and organizing mission trips
        for the age group.
      </p>
      <h3>Fun Fact</h3>
      <hr/>
      <p>
        Chloe has a Great Pyrenees dog that weighs more than her!
      </p>
      <h3>Why I love Bandwagon</h3>
      <hr/>
      <p>
        The passion. I met Harold while serving at a restaurant, and he
        immediately saw a potential in me. His passion, along with the team’s
        sense of community, ignited the same passion for Bandwagonfanclub in me.
      </p>
  </TeamMember>
</BandwagonDotCom>;

export default ChloeFowler
